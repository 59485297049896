body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
  width: 100%;
  height: 100%
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 100px;
}

.App-header {
  min-height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  font-family: 'Archivo', sans-serif;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

body {
  background-color: #F05476;
}

td {
  border: none;
  width: 50%;
  padding: 1%;
}

table {
  padding-top: 5px;
  font-weight: bold;
}

.SpecialText {
  font-weight: normal;
  font-family: 'Archivo', sans-serif;
}

.Blackout {
  background-color: #000000;
  opacity: 0.5;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 100;
}

.WelcomeDialogBorder {
  position: absolute;
  background-color: #F05476;
  opacity: 1.0;
  width: 50%;
  height: 70%;
  margin: auto;
  top: 0; left: 0; bottom: 0; right: 0;
  z-index: 100;
  border-radius: 2em;
  min-height: 400px;
  min-width: 620px;
  max-height: 500px;
  max-width: 800px;
}

.WelcomeDialogTitle {
  z-index: 101;
  background-color: #F05476;
  margin: 0 auto;
  font-family: 'Archivo', sans-serif;
  padding: 5px;
  font-size: 1.5em;
  width: 50%;
}

.Filler {
  -webkit-flex: 1 1;
          flex: 1 1;
}

.WelcomeDialogInnerLayout.hidden {
  display: none;
}

.WelcomeDialogInnerLayout {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 100%;
}

.WelcomeDialogLayout {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column;
          flex-flow: column;
  height: 100%;
  overflow: hidden;
}

.WelcomeDialog {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column;
          flex-flow: column;
  position: relative;
  background-color: #F05476;
  opacity: 2.0;
  width: 80%;
  height: 90%;
  margin: 0 auto;
  top: 0; left: 0; bottom: 0; right: 0;
  padding: 1em;
  z-index: 100;
  border-style: solid;
  border-width: 3px;
  border-color: #70C9C6;
  border-radius: 1em;
  overflow: hidden;
}

.WelcomeDialogBorder .Button {
  position: relative;
  margin: 0 auto;
  padding-bottom: 10;
  width: 70%;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #F05476;
  z-index: 101;
  font-size: 1.5em;
  border-radius: 0em;
  padding: 5px;
}

.WelcomeDialogBorder .Button:hover {
  color: #70C9C6;
}

.Rhythm {
  position: relative;
  padding-top: 20px; 
  padding-bottom: 20px;
  background-color: #70C9C6;
}

.Melody {
  position: relative;
  padding-top: 20px; 
  padding-bottom: 20px;
  background-color: #F05476;
}

.SectionBackground {
  display: -webkit-flex;
  display: flex;
  position: absolute;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  opacity: 0.2;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 0;
  overflow: hidden;
  font-family: 'Archivo', sans-serif;
  font-size: 8em;
  font-weight: 600;
}

.PlayControls {
  position: relative;
  padding-top: 20px; 
  padding-bottom: 20px;
  background-color: #70C9C6;
}

.Button { /* Safari */
  transition-duration: 0.4s;
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  font-size: 2em;
  position: relative;
  z-index: 3;
  font-family: 'Archivo', sans-serif;
}

.PlayControls .Button:hover:enabled {
  color: #F05476;
}

.Melody .Button:hover:enabled {
  color: #70C9C6;
}

.Rhythm .Button:hover:enabled {
  color: #F05476;
}

.VolumeControls .Button {
  font-size: 1em;
}
